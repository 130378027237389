module.exports = {
  siteTitle: 'Jonathan Siu - Software Engineer', // <title>
  manifestName: 'Identity',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#557A95',
  manifestThemeColor: '#557A95',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'Jonathan Siu',
  heading: 'Software Engineer @ Humi',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/jonsiu',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/jonsiu',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/jonsiu',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:jonpsiu@gmail.com',
    },
  ],
};
